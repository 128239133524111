.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.spinner {
  width: 40px; /* Size of the spinner */
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light outer border */
  border-top: 4px solid #3498db; /* Accent color for animation */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Smooth spinning effect */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

p {
  margin-top: 10px;
  font-size: 16px;
  color: #555; /* Optional: Styling for the text */
}
